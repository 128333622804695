import type { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import { ATTACHMENT_STATUS, type AttachmentStatusOption } from 'qonto/react/models/transaction';

export const updateOtherBulkFields = async (
  fetchApi: ReturnType<typeof useFetchApi>,
  payload: {
    updatedLabelIds: string[];
    transactionIds: string[];
    organizationId: string;
    category: string;
    requestAttachment: boolean;
  }
): Promise<void> => {
  try {
    const response = await fetchApi(`v1/bulk_actions`, {
      method: 'POST',
      body: JSON.stringify({
        bulk_action: {
          class: 'transaction',
          organization_id: payload.organizationId,
          ids: payload.transactionIds,
          fields: {
            label_ids: payload.updatedLabelIds,
            activity_tag: payload.category,
            request_attachment: payload.requestAttachment,
          },
        },
      }),
    });
    if (!response.ok) throw Error('Your changes could not be saved');
  } catch (e) {
    throw new Error('Your changes could not be saved');
  }
};

export const updateBulkVerificationStatus = async (
  fetchApi: ReturnType<typeof useFetchApi>,
  organizationId: string,
  {
    transactionIds,
    qualifiedForAccounting,
  }: {
    transactionIds: [string, ...string[]];
    qualifiedForAccounting: boolean;
  }
): Promise<void> => {
  const endpointPath = qualifiedForAccounting
    ? 'v7/transactions/disqualify_for_accounting'
    : 'v7/transactions/qualify_for_accounting';
  const response = await fetchApi(endpointPath, {
    method: 'POST',
    body: JSON.stringify({
      organization_id: organizationId,
      transaction_ids: transactionIds,
    }),
  });
  if (!response.ok) throw Error('verification status: your changes could not be saved');
};

export const updateBulkAttachmentStatus = async (
  fetchApi: ReturnType<typeof useFetchApi>,
  organizationId: string,
  {
    transactionIds,
    attachmentStatus,
  }: {
    transactionIds: [string, ...string[]];
    attachmentStatus: AttachmentStatusOption | null;
  }
): Promise<void> => {
  const endpointPath = 'v7/transactions/attachment_statuses';
  const lost = attachmentStatus === ATTACHMENT_STATUS.LOST;
  const required = attachmentStatus === ATTACHMENT_STATUS.REQUIRED || lost;

  const response = await fetchApi(endpointPath, {
    method: 'PATCH',
    body: JSON.stringify({
      organization_id: organizationId,
      lost,
      required,
      transaction_ids: transactionIds,
    }),
  });
  if (!response.ok) throw Error('attachment status: your changes could not be saved');
};
