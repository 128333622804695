import type { Amount } from 'qonto/react/models/amount';
import type { CashflowCategories } from 'qonto/react/models/cash-flow-category';

export interface TimeframeInterval {
  inclusiveStartDate: string;
  exclusiveEndDate: string;
}

export interface CashflowDataPoint {
  transactionsCount: number;
  amountSum: Amount;
}

export interface CashflowSubcategoryDataPoint extends CashflowDataPoint {
  subcategoryId: string;
}

export interface CashflowCategoryDataPoint extends CashflowDataPoint {
  categoryId: string;
  subcategoriesDataPoints?: CashflowSubcategoryDataPoint[];
}

export interface CashflowSideDatapoint extends CashflowDataPoint {
  categoriesDataPoints: CashflowCategoryDataPoint[];
  growthRateOverPreviousPeriod: number;
}

export interface CashflowVAT {
  creditAmount: Amount;
  debitAmount: Amount;
}

export interface CashflowTimeframeDatapoint {
  transactionsCount: number;
  startingAmount: Amount;
  endingAmount: Amount;
  inflows: CashflowSideDatapoint;
  outflows: CashflowSideDatapoint;
  vat?: CashflowVAT;
}

export interface CashflowTimeframe extends TimeframeInterval {
  cashFlowData: CashflowTimeframeDatapoint;
}

export interface CashflowTimeseries {
  timeframe: TimeframeInterval;
  categoriesData: CashflowCategories;
  timeframes: CashflowTimeframe[];
}

export type CashflowSide = 'inflows' | 'outflows';

export const DEFAULT_TIMESERIES = {
  timeframe: { inclusiveStartDate: '', exclusiveEndDate: '' },
  categoriesData: { inflow: [], outflow: [] },
  timeframes: [],
};
