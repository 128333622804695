import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import {
  CASHFLOW_REQUEST_DATE_FORMAT,
  type CashflowTimeframeRequest,
} from 'qonto/react/api/models/cash-flow-timeseries';
import {
  CashflowPeriodInterval,
  CashflowPeriodKey,
  type CashflowPeriod,
} from 'qonto/react/models/cash-flow-period';
import type Organization from 'qonto/models/organization';

export const getPeriodTimeframeInterval = (period?: CashflowPeriod): CashflowTimeframeRequest => {
  extend(quarterOfYear);
  extend(utc);

  const now = dayjs().utc();
  const today = now.format(CASHFLOW_REQUEST_DATE_FORMAT);
  const last30days = now.subtract(30, 'days').format(CASHFLOW_REQUEST_DATE_FORMAT);
  const last3months = now.subtract(2, 'months').format(CASHFLOW_REQUEST_DATE_FORMAT);
  const last12months = now.subtract(11, 'months').format(CASHFLOW_REQUEST_DATE_FORMAT);
  const startOfMonth = now.startOf('month').format(CASHFLOW_REQUEST_DATE_FORMAT);
  const startOfQuarter = now.startOf('quarter').format(CASHFLOW_REQUEST_DATE_FORMAT);
  const startOfYear = now.startOf('year').format(CASHFLOW_REQUEST_DATE_FORMAT);

  if (
    period &&
    period.key === CashflowPeriodKey.CustomPeriod &&
    period.startDate &&
    period.endDate
  ) {
    return {
      start_year_month: dayjs(period.startDate).format(CASHFLOW_REQUEST_DATE_FORMAT),
      end_year_month: dayjs(period.endDate).format(CASHFLOW_REQUEST_DATE_FORMAT),
    };
  }

  switch (period?.interval) {
    case CashflowPeriodInterval.CurrentMonth:
      return { start_year_month: startOfMonth, end_year_month: today };
    case CashflowPeriodInterval.CurrentQuarter:
      return { start_year_month: startOfQuarter, end_year_month: today };
    case CashflowPeriodInterval.CurrentYear:
      return { start_year_month: startOfYear, end_year_month: today };
    case CashflowPeriodInterval.Last30days:
      return { start_year_month: last30days, end_year_month: today };
    case CashflowPeriodInterval.Last3months:
      return { start_year_month: last3months, end_year_month: today };
    case CashflowPeriodInterval.Last12months:
      return { start_year_month: last12months, end_year_month: today };
    default:
      return { start_year_month: last12months, end_year_month: today };
  }
};

export const getOrganizationTimeframeInterval = (
  organization?: Organization
): CashflowTimeframeRequest => {
  extend(utc);

  return {
    start_year_month:
      organization?.createdAt && dayjs(organization.createdAt as Date).isValid()
        ? dayjs(organization.createdAt as Date).format(CASHFLOW_REQUEST_DATE_FORMAT)
        : dayjs().utc().subtract(11, 'months').format(CASHFLOW_REQUEST_DATE_FORMAT),
    end_year_month: dayjs().utc().format(CASHFLOW_REQUEST_DATE_FORMAT),
  };
};
