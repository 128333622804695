import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import type {
  CashflowCategory,
  CashflowCategorySide,
  CashflowParentCategory,
} from '../models/cash-flow-category';
import { useCategoriesManagementRouting } from '../components/cash-flow/components/categories-management/hooks/use-categories-management-routing';
import { useFetchApi } from './use-fetch-api';
import { useCashflowCategories } from './use-cashflow-categories';

interface ErrorDetails {
  errors: {
    code: string;
    detail: string;
    source: unknown;
  }[];
}

export interface PatchCashFlowSubcategoryRequest {
  type: CashflowCategorySide;
  categoryId: string;
  subcategoryId: string;
  reorderedSubcategories?: CashflowCategory[];
  payload: {
    order?: number;
    name?: string;
    vat_rate?: {
      value: string | null;
      valid: boolean;
    };
  };
}
export const useUpdateCashFlowSubcategory = (): UseMutationResult<
  void,
  Error,
  PatchCashFlowSubcategoryRequest
> => {
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const {
    setSubcategory,
    reorderSubcategories,
    setAllCategories,
    cancelCategoriesQueries,
    invalidateCategoriesQuery,
  } = useCashflowCategories();
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const patchCashFlowSubcategory = async (
    request: PatchCashFlowSubcategoryRequest
  ): Promise<void> => {
    const { categoryId, subcategoryId, payload } = request;
    const response = await fetchApi(
      `${cashFlowCategoriesNamespace}/cash-flow/categories/${categoryId}/subcategories/${subcategoryId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }
    );
    if (!response.ok) {
      const errorDetails = (await response.json()) as ErrorDetails;
      throw new Error("We couldn't update subcategory", { cause: errorDetails.errors[0]?.code });
    }
  };

  return useMutation({
    mutationFn: patchCashFlowSubcategory,
    onMutate: async ({ categoryId, type, subcategoryId, reorderedSubcategories, payload }) => {
      await cancelCategoriesQueries();

      if (reorderedSubcategories) {
        const { allCategoriesBeforeMutation } = reorderSubcategories(
          categoryId,
          type,
          reorderedSubcategories
        );
        return { categoryId, allCategoriesBeforeMutation };
      }

      const { allCategoriesBeforeMutation } = setSubcategory(
        categoryId,
        subcategoryId,
        type,
        payload
      );
      return { categoryId, allCategoriesBeforeMutation };
    },
    onError: (error, __, context) => {
      if (context?.allCategoriesBeforeMutation) {
        setAllCategories(context.allCategoriesBeforeMutation);
      }
      if (error.cause === 'invalid_order') {
        toastFlashMessages.toastError(
          formatMessage({ id: 'categories-management.reorder.toast.error' })
        );
      } else if (error.cause === 'invalid_name') {
        toastFlashMessages.toastError(
          formatMessage({ id: 'categories-management.create-category.already-exists.toast.error' })
        );
      } else {
        toastFlashMessages.toastError(
          formatMessage({ id: 'categories-management.edit-subcategory.toast.error' })
        );
      }
    },
    onSuccess: async () => {
      await invalidateCategoriesQuery();
    },
  });
};

export interface PatchCashFlowCategoryRequest {
  type: CashflowCategorySide;
  categoryId: string;
  reorderedCategories?: CashflowParentCategory[];
  payload: {
    order?: number;
    name?: string;
    icon_key?: string;
    color_key?: string;
    type?: CashflowCategorySide;
    vat_rate?: {
      value: string | null;
      valid: boolean;
    };
  };
}
export const useUpdateCashFlowCategory = (): UseMutationResult<
  void,
  Error,
  PatchCashFlowCategoryRequest
> => {
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const {
    reorderCategoriesByType,
    setAllCategories,
    setCategory,
    cancelCategoriesQueries,
    invalidateCategoriesQuery,
  } = useCashflowCategories();
  const { navigateToType } = useCategoriesManagementRouting();
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const patchCashFlowCategory = async (request: PatchCashFlowCategoryRequest): Promise<void> => {
    const { categoryId, payload } = request;
    const response = await fetchApi(
      `${cashFlowCategoriesNamespace}/cash-flow/categories/${categoryId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }
    );
    if (!response.ok) {
      const errorDetails = (await response.json()) as ErrorDetails;
      throw new Error("We couldn't update category", { cause: errorDetails.errors[0]?.code });
    }
  };

  return useMutation({
    mutationFn: patchCashFlowCategory,
    onMutate: async ({ categoryId, type, reorderedCategories, payload }) => {
      await cancelCategoriesQueries();

      if (reorderedCategories) {
        const { allCategoriesBeforeMutation } = reorderCategoriesByType(type, reorderedCategories);
        return { categoryId, allCategoriesBeforeMutation };
      }

      const { allCategoriesBeforeMutation, hasTypeChanged } = setCategory(
        type,
        categoryId,
        payload
      );
      return { categoryId, allCategoriesBeforeMutation, hasTypeChanged };
    },
    onError: (error, __, context) => {
      if (context) {
        setAllCategories(context.allCategoriesBeforeMutation);
      }
      if (error.cause === 'invalid_order') {
        toastFlashMessages.toastError(
          formatMessage({ id: 'categories-management.reorder.toast.error' })
        );
      } else if (error.cause === 'invalid_name') {
        toastFlashMessages.toastError(
          formatMessage({ id: 'categories-management.create-category.already-exists.toast.error' })
        );
      } else {
        toastFlashMessages.toastError(
          formatMessage({ id: 'categories-management.edit-category.toast.error' })
        );
      }
    },
    onSuccess: async (_, { payload }, { hasTypeChanged }) => {
      await invalidateCategoriesQuery();
      if (hasTypeChanged && payload.type) {
        navigateToType(payload.type);
      }
    },
  });
};
