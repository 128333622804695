import { useQueries, type UseQueryResult } from '@tanstack/react-query';
import { useCallback } from 'react';
import type { Membership } from '../models/membership';
import { useFetchApi } from './use-fetch-api';
import { fetchMembership } from './use-fetch-membership';

export interface MembershipQueryResult {
  data: (Membership | undefined)[];
  isPending: boolean;
  isError: boolean;
}

export function useFetchMembershipList(membershipIds: string[]): MembershipQueryResult {
  const fetchApi = useFetchApi();

  const getSingleMembership = useCallback(
    (membershipId: string) => async () => {
      return fetchMembership(membershipId, fetchApi);
    },
    [fetchApi]
  );

  // Fetching individual memberships in order to cache them individually.
  // This is beacuse threads can have shared and non-shared participants.
  // Individual caching avoids having to refetch a full list when a participant differs in a thread.
  // TODO: Needs to be rewritten to use the new graphql endpoint.
  const allMemberships = useQueries({
    queries: membershipIds.map(membershipId => ({
      queryKey: ['membership', membershipId],
      queryFn: getSingleMembership(membershipId),
      staleTime: 1000 * 60 * 30, // 30 min
    })),
    combine: useCallback((results: UseQueryResult<Membership | undefined>[]) => {
      return {
        data: results.map(result => result.data),
        isPending: results.some(result => result.isPending),
        isError: results.some(result => result.isError),
      };
    }, []),
  });

  return allMemberships;
}
