import { useMutation, useQueryClient, type UseMutateFunction } from '@tanstack/react-query';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';

interface MutationVars {
  threadId: string;
  read: boolean;
}

export const useToggleReadStatus = (): {
  mutate: UseMutateFunction<void, Error, MutationVars>;
} => {
  const queryClient = useQueryClient();
  const fetchApi = useFetchApi();

  const toggleReadStatus = async ({ threadId, read }: MutationVars): Promise<void> => {
    const response = await fetchApi(`v1/threads/${String(threadId)}/read_status`, {
      method: 'PATCH',
      body: JSON.stringify({
        data: {
          type: 'thread',
          attributes: {
            read: !read,
          },
        },
      }),
    });
    if (!response.ok) throw Error('Your changes could not be saved');
  };

  const { mutate } = useMutation({
    mutationFn: toggleReadStatus,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['search-transactions-graphql'] });
    },
  });

  return { mutate };
};
