import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import type { LabelList } from '../models/label';
import { normalizeLabelLists } from '../api/label-lists/normalize';
import type { LabelLists } from '../api/models';
import { useFetchApi } from './use-fetch-api';

export function useFetchLabelLists(organizationId: string): UseQueryResult<LabelList[]> {
  const fetchApi = useFetchApi();
  const endpointUrl = `v3/label_lists?organization_id=${organizationId}&includes%5B%5D=labels`;

  return useQuery({
    queryKey: ['label-lists'],
    queryFn: async () => {
      const response = await fetchApi(endpointUrl);

      if (!response.ok) {
        throw new Error('Error fetching label lists');
      }

      const data = (await response.json()) as LabelLists;
      return normalizeLabelLists(data);
    },
  });
}
