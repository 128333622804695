export type CashflowCategorySide = 'inflow' | 'outflow';
export const cashflowCategorySides = ['inflow', 'outflow'] as CashflowCategorySide[];

export interface CashflowCategory {
  colorKey: string;
  iconKey?: string;
  id: string | null;
  vatRate: number | null;
  name: string;
  nameKey: string | null;
  parentCategoryId?: string | null;
  parentCategoryVatRate?: number | null;
}

export interface CashflowSearchCategory extends CashflowCategory {
  type: CashflowCategorySide;
}

export interface CashflowParentCategory extends CashflowCategory {
  subcategories?: CashflowCategory[];
}

export interface CashflowCategories {
  inflow: CashflowParentCategory[];
  outflow: CashflowParentCategory[];
}

export type CashflowActiveCategoryId = string | null;

export interface CashflowCategoryAssignment {
  transactionIds: string[];
  categoryId: CashflowActiveCategoryId;
}

export interface CashflowTransactionCategory {
  id: string;
  source: string;
}

export const UNCATEGORIZED_CATEGORY: CashflowCategory = {
  colorKey: 'uncategorized',
  id: null,
  vatRate: null,
  name: 'Uncategorized',
  nameKey: 'uncategorized',
};
