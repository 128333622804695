export enum FilterConditional {
  And = 'and',
  Or = 'or',
}

export enum FilterExpressionOperator {
  Eq = 'eq',
  NotEq = 'not_eq',
  Lt = 'lt',
  Gt = 'gt',
  Gte = 'gte',
  Lte = 'lte',
  Interval = 'interval',
  Within = 'within',
  Exists = 'exists',
  NotExists = 'not_exists',
  In = 'in',
  NotIn = 'not_in',
}

export interface FilterExpression {
  property: string;
  operator: FilterExpressionOperator;
  values: (string | number)[];
}

export interface FilterGroup {
  conditional: FilterConditional;
  expressions: (FilterExpression | FilterGroup)[];
}
