import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { GraphQLClient, type RequestDocument } from 'graphql-request';
import { apiBaseURL } from 'qonto/constants/hosts';
import type { TransactionsSearch } from '../graphql/graphql';

const graphQLEndpointUrl = `${apiBaseURL}/aggregates/v1/graphql`;
const client = new GraphQLClient(graphQLEndpointUrl, {
  method: 'POST',
  credentials: 'include',
});

export function useGraphQLRequest<TData>(): (
  request: RequestDocument,
  options?: TransactionsSearch
) => Promise<TData> {
  const networkManager = useEmberService('network-manager');
  client.setHeaders(networkManager.requestHeaders);

  return (request: RequestDocument, options?: TransactionsSearch) =>
    client.request(request, options);
}
