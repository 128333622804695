import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import type { CashflowCategorySide } from '../models/cash-flow-category';
import { useFetchApi } from './use-fetch-api';
import { useCashflowCategories } from './use-cashflow-categories';

interface NewCategory {
  color_key: string;
  type: CashflowCategorySide;
  icon_key: string;
  name: string;
  vat_rate: string | null;
}

interface ErrorDetails {
  errors: {
    code: string;
    detail: string;
    source: unknown;
  }[];
}
export interface CreateCashFlowCategoryRequest {
  payload: NewCategory;
}
interface CreateCashFlowCategoryResponse extends NewCategory {
  id: string;
  order: number;
}

export const useCreateCashFlowCategory = (): UseMutationResult<
  CreateCashFlowCategoryResponse,
  Error,
  CreateCashFlowCategoryRequest
> => {
  const fetchApi = useFetchApi();
  const intl = useIntl();
  const { invalidateCategoriesQuery, cancelCategoriesQueries, setNewCategory, setAllCategories } =
    useCashflowCategories();
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const createCashFlowCategory = async (
    request: CreateCashFlowCategoryRequest
  ): Promise<CreateCashFlowCategoryResponse> => {
    const { payload } = request;
    const response = await fetchApi(`${cashFlowCategoriesNamespace}/cash-flow/categories`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      const errorDetails = (await response.json()) as ErrorDetails;
      throw new Error('Category could not be created', { cause: errorDetails.errors[0]?.code });
    }

    return (await response.json()) as CreateCashFlowCategoryResponse;
  };

  return useMutation({
    mutationFn: createCashFlowCategory,
    onMutate: async ({ payload }) => {
      await cancelCategoriesQueries();

      const { allCategoriesBeforeMutation } = setNewCategory(payload);

      return { allCategoriesBeforeMutation };
    },
    onError: (error, __, context) => {
      if (context) {
        setAllCategories(context.allCategoriesBeforeMutation);
      }

      if (error.cause === 'name_already_used') {
        toastFlashMessages.toastError(
          intl.formatMessage({
            id: 'categories-management.create-category.already-exists.toast.error',
          })
        );
      } else {
        toastFlashMessages.toastError(
          intl.formatMessage({ id: 'categories-management.create-category.toast.error' })
        );
      }
    },
    onSuccess: async () => {
      await invalidateCategoriesQuery();
      toastFlashMessages.toastInfo(
        intl.formatMessage({ id: 'categories-management.create-category.toast.success' })
      );
    },
  });
};
