import { useQueryClient } from '@tanstack/react-query';
import type { Label, Transaction } from 'qonto/react/graphql';
import { type LabelList } from 'qonto/react/models/label';
import { manageActiveTransactionsQueryCache } from 'qonto/react/utils/react-query-helpers';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';

type LabelLists = LabelList[];

interface TransactionLabelWithId extends Label {
  transactionId: string;
}

type TransactionsLabelsMap = Record<string, TransactionLabelWithId[]>;

export interface BulkLabelList extends LabelList {
  sharedLabel: TransactionLabelWithId | null;
}

/**
 * Matches the selected transactions labels with the available label lists
 * and returns these lists enhanced with matching transactions data
 */
const useBulkLabels = (
  labelLists: LabelLists
): {
  transactionIds: string[];
  transactions: (Transaction | undefined)[];
  bulkLabelLists: BulkLabelList[];
} => {
  const queryClient = useQueryClient();
  const { selection: transactionIds } = bulkSelectionManager.useBulkSelection();
  if (!transactionIds[0]) return { transactionIds, transactions: [], bulkLabelLists: [] };

  const { transactions } = manageActiveTransactionsQueryCache(transactionIds[0], queryClient);
  const selectedTransactions = transactionIds.map(id => transactions.find(t => t.id === id));

  const transactionsLabelsMap: TransactionsLabelsMap = {};
  selectedTransactions.map(transaction => {
    return transaction?.labels.forEach(label => {
      if (!transactionsLabelsMap[label.listId]) transactionsLabelsMap[label.listId] = [];
      transactionsLabelsMap[label.listId]?.push({ ...label, transactionId: transaction.id });
    });
  });

  const bulkLabelLists = labelLists.map(list => ({
    ...list,
    sharedLabel: getSharedLabel(list.id, transactionsLabelsMap, selectedTransactions),
  }));

  return {
    transactionIds,
    transactions: selectedTransactions,
    bulkLabelLists,
  };
};

export const bulkLabelsManager = {
  useBulkLabels,
};

export const areLabelsEqual = (labels: TransactionLabelWithId[]): boolean => {
  return labels.every(obj => obj.id === labels[0]?.id);
};

export const getSharedLabel = (
  labelListId: string,
  transactionsLabelsMap: TransactionsLabelsMap,
  selectedTransactions: (Transaction | undefined)[]
): TransactionLabelWithId | null => {
  const matchingListLabels = transactionsLabelsMap[labelListId];

  if (!matchingListLabels) return null;
  if (matchingListLabels.length < selectedTransactions.length) return null;
  if (matchingListLabels.length === selectedTransactions.length) {
    if (areLabelsEqual(matchingListLabels)) return matchingListLabels[0] ?? null;
  }
  return null;
};
