import { useQueryClient } from '@tanstack/react-query';
import { manageActiveTransactionsQueryCache } from 'qonto/react/utils/react-query-helpers';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';
import type { Transaction } from 'qonto/react/graphql';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';

export const useBulkRequestAttachment = (): {
  disabledRequestAttachment: boolean;
  showRequestAttachment: boolean;
} => {
  const queryClient = useQueryClient();
  const { membership } = useOrganizationManager();
  const { selection: selectedTransactionIds } = bulkSelectionManager.useBulkSelection();
  const showRequestAttachment = true;
  let disabledRequestAttachment = false;

  if (!selectedTransactionIds[0]) return { disabledRequestAttachment, showRequestAttachment };

  const { getTransactionsFromIds } = manageActiveTransactionsQueryCache(
    selectedTransactionIds[0],
    queryClient
  );
  const selectedTransactions = getTransactionsFromIds(selectedTransactionIds);
  if (!selectedTransactions[0]) return { disabledRequestAttachment, showRequestAttachment };

  if (areAllTransactionsInitiatedByCurrentMember(selectedTransactions, membership.membershipId))
    disabledRequestAttachment = true;
  return { disabledRequestAttachment, showRequestAttachment };
};

export const bulkRequestAttachmentManager = {
  useBulkRequestAttachment,
};

export const areAllTransactionsInitiatedByCurrentMember = (
  selectedTransactions: Transaction[],
  currentMembershipId: string
): boolean => {
  return selectedTransactions.every(({ initiator }) => {
    return !initiator?.id || initiator.id === currentMembershipId;
  });
};
