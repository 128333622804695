import { useQueryClient } from '@tanstack/react-query';
import { manageActiveTransactionsQueryCache } from 'qonto/react/utils/react-query-helpers';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';
import type { Transaction } from 'qonto/react/graphql';

export const useBulkVerificationStatus = (): { verificationStatus: boolean | null } => {
  const queryClient = useQueryClient();
  const { selection: selectedTransactionIds } = bulkSelectionManager.useBulkSelection();
  if (!selectedTransactionIds[0]) return { verificationStatus: false };

  const { getTransactionsFromIds } = manageActiveTransactionsQueryCache(
    selectedTransactionIds[0],
    queryClient
  );
  const selectedTransactions = getTransactionsFromIds(selectedTransactionIds);

  if (areAllTransactionsNotVerified(selectedTransactions)) return { verificationStatus: false };
  if (areAllTransactionsVerified(selectedTransactions)) return { verificationStatus: true };
  return { verificationStatus: null };
};

export const bulkVerificationStatusManager = {
  useBulkVerificationStatus,
};

export const areAllTransactionsVerified = (selectedTransactions: Transaction[]): boolean =>
  selectedTransactions.every(transaction => transaction.qualifiedForAccounting);

export const areAllTransactionsNotVerified = (selectedTransactions: Transaction[]): boolean =>
  selectedTransactions.every(transaction => !transaction.qualifiedForAccounting);
