import { useEffect, useRef, useCallback } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';

export interface UseTrackRenderProps {
  eventName: string;
  params?: Record<string, unknown>;
  delay?: number;
}

export function useTrackRender({ eventName, params = {}, delay }: UseTrackRenderProps): void {
  const segment = useEmberService('segment');
  const trackedRef = useRef(false);

  const emitEvent = useCallback(() => {
    segment.track(eventName, params);
    trackedRef.current = true;
  }, [segment, eventName, params]);

  useEffect(() => {
    if (trackedRef.current) return;
    if (delay) {
      const timeout = setTimeout(emitEvent, delay);
      return () => {
        clearTimeout(timeout);
      };
    }
    emitEvent();
  }, [emitEvent, delay]);
}
