import { useMutation, type UseMutateFunction } from '@tanstack/react-query';
import { useFetchApi } from '../use-fetch-api';
import { useOrganizationManager } from '../use-organization-manager';
import { useRefetchTransaction } from './use-refetch-transaction';

export const useRequestAttachment = (
  transactionId: string
): { isPending: boolean; isError: boolean; mutate: UseMutateFunction<void> } => {
  const { mutate: refetchTransaction, isPending: isRefetchingTransaction } =
    useRefetchTransaction(transactionId);
  const { organization } = useOrganizationManager();
  const fetchApi = useFetchApi();

  const requestAttachment = async (): Promise<void> => {
    try {
      const response = await fetchApi(`v1/bulk_actions`, {
        method: 'POST',
        body: JSON.stringify({
          bulk_action: {
            class: 'transaction',
            organization_id: organization.organizationId,
            ids: [transactionId],
            fields: {
              request_attachment: true,
            },
          },
        }),
      });
      if (!response.ok) throw Error('Your changes could not be saved');
    } catch (e) {
      throw new Error('Your changes could not be saved');
    }
  };

  const { mutate, isPending, isError } = useMutation({
    mutationFn: requestAttachment,
    onSuccess: () => {
      refetchTransaction();
    },
  });

  return { mutate, isPending: isPending || isRefetchingTransaction, isError };
};
