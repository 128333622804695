import { useMutation, useQueryClient, type UseMutateFunction } from '@tanstack/react-query';
import { transactionNamespace } from 'qonto/constants/hosts';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import type { Transaction } from 'qonto/react/graphql';
import { manageActiveTransactionsQueryCache } from '../utils/react-query-helpers';

interface MutationPayload {
  categoryValue: string;
  closePopover: () => void;
}

export const useUpdateTransactionCategory = (
  transactionId: string
): { mutate: UseMutateFunction<void, Error, MutationPayload> } => {
  const queryClient = useQueryClient();
  const fetchApi = useFetchApi();

  const updateTransactionCategory = async (payload: MutationPayload): Promise<void> => {
    try {
      const response = await fetchApi(
        `${transactionNamespace}/transactions/${transactionId}/activity`,
        {
          method: 'PUT',
          body: JSON.stringify({
            transaction: {
              activity_tag: payload.categoryValue,
            },
          }),
        }
      );
      if (!response.ok) throw Error('Category could not be saved');
    } catch (e) {
      throw new Error('Category could not be saved');
    }
  };

  const { mutate } = useMutation({
    mutationFn: updateTransactionCategory,
    onMutate: async ({ categoryValue, closePopover }) => {
      const { transaction, updateTransactionCache, activeQueryKey } =
        manageActiveTransactionsQueryCache(transactionId, queryClient);

      await queryClient.cancelQueries({ queryKey: activeQueryKey });
      const updatedTransaction = {
        ...transaction,
        activityTag: categoryValue,
      } as Transaction;

      updateTransactionCache(updatedTransaction);
      closePopover();

      return {
        oldTransaction: transaction,
        updateTransactionCache,
      };
    },
    onError: (err, variables, context) => {
      if (context) {
        const { oldTransaction, updateTransactionCache } = context;
        updateTransactionCache(oldTransaction);
      }
    },
  });

  return { mutate };
};
