import { useLocalStorage } from 'usehooks-ts';

type Collection =
  | 'cash-flow-category-selector-expanded-states'
  | 'cash-flow-categories-management-expanded-states';

export const useCashFlowCategoriesStateStorage = (
  selectedCollection: Collection,
  key: string | null,
  startAsExpanded = false
): {
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
} => {
  const [collection, setCollection] = useLocalStorage<Record<string, boolean>>(
    selectedCollection,
    {}
  );

  const isExpanded = key ? (collection[key] ?? startAsExpanded) : startAsExpanded;

  const setIsExpanded = (value: boolean): void => {
    setCollection(key ? { ...collection, [key]: value } : { ...collection });
  };

  return {
    isExpanded,
    setIsExpanded,
  };
};
