import { useMemo, useRef } from 'react';
import { isEqual, cloneDeep } from 'es-toolkit';

export const useDeepCompareMemoize = <T>(value: T): T => {
  const ref = useRef<T>(value);
  if (!isEqual(value, ref.current)) {
    ref.current = cloneDeep(value);
  }
  return ref.current;
};

export const useDeepMemo = <T>(factory: () => T, dependencies: React.DependencyList): T => {
  return useMemo(factory, [factory, useDeepCompareMemoize(dependencies)]);
};
