export enum CashflowPeriodInterval {
  CurrentMonth = 'current_month',
  CurrentQuarter = 'current_quarter',
  CurrentYear = 'current_year',
  Last30days = 'last_30_days',
  Last3months = 'last_3_months',
  Last12months = 'last_12_months',
}

export enum CashflowPeriodKey {
  CurrentMonth = 'month_to_date',
  CurrentQuarter = 'quarter_to_date',
  CurrentYear = 'year_to_date',
  Last30days = 'last_30_days',
  Last3months = 'last_3_months',
  Last12months = 'last_12_months',
  CustomPeriod = 'custom_period',
}

export type CashflowPeriodRate = 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'yearly';

export interface CashflowPeriod {
  interval: CashflowPeriodInterval;
  key: CashflowPeriodKey;
  label: string;
  rate?: CashflowPeriodRate;
  startDate?: string;
  endDate?: string;
}
