export enum InstallmentStatus {
  NotPaid = 'not_paid',
  Paid = 'paid',
  Overdue = 'overdue',
  AtRisk = 'at_risk',
}

export interface InstallmentModel {
  status: InstallmentStatus;
  totalAmount: {
    value: string;
    currency: string;
  };
  date: string;
}
