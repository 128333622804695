import { useQueryClient } from '@tanstack/react-query';
import { manageActiveTransactionsQueryCache } from 'qonto/react/utils/react-query-helpers';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';
import type { Transaction } from 'qonto/react/graphql';

export const useBulkCategory = (): { category: string | null } => {
  const queryClient = useQueryClient();
  const { selection: selectedTransactionIds } = bulkSelectionManager.useBulkSelection();
  if (!selectedTransactionIds[0]) return { category: null };

  const { getTransactionsFromIds } = manageActiveTransactionsQueryCache(
    selectedTransactionIds[0],
    queryClient
  );
  const selectedTransactions = getTransactionsFromIds(selectedTransactionIds);

  if (areAllCategoriesEqual(selectedTransactions))
    return { category: selectedTransactions[0]?.activityTag?.toLowerCase() ?? null };
  return { category: null };
};

export const bulkCategoryManager = {
  useBulkCategory,
};

export const areAllCategoriesEqual = (selectedTransactions: Transaction[]): boolean => {
  return selectedTransactions.every(
    transaction => transaction.activityTag === selectedTransactions[0]?.activityTag
  );
};
