import { useMutation, useQueryClient, type UseMutateFunction } from '@tanstack/react-query';
import {
  graphql,
  type SearchTransactionsQuery,
  type SearchTransactionsResult,
} from 'qonto/react/graphql';
import { manageActiveTransactionsQueryCache } from '../../utils/react-query-helpers';
import { useGraphQLRequest } from '../use-graphql-request';

export const useRefetchTransaction = (
  transactionId: string
): {
  mutate: UseMutateFunction<SearchTransactionsQuery['getTransactionById']>;
  isPending: boolean;
} => {
  const queryClient = useQueryClient();
  const request = useGraphQLRequest<SearchTransactionsQuery>();

  const fetchUpdatedTransaction = async (): Promise<SearchTransactionsResult> => {
    const { searchTransactions } = await request(
      graphql(/* GraphQL */ `
        query getTransactionById(
          $search: String
          $pagination: Pagination!
          $sort: Sort!
          $filterGroup: FilterGroupOne
          $bankAccountIds: [String!]
        ) {
          searchTransactions(
            input: {
              search: $search
              sort: $sort
              pagination: $pagination
              filterGroup: $filterGroup
              bankAccountIds: $bankAccountIds
            }
          ) {
            transactions {
              activityTag
              attachmentLost
              attachmentRequired
              attachmentRequestedAt
              automaticallyAddedAttachmentIds
              note
              qualifiedForAccounting
              labels {
                id
                name
                labelList {
                  id
                  name
                  color
                  rank
                }
                listId
              }
              attachments {
                thumbnail {
                  fileUrl
                }
                slug
                id
                downloadUrl
                createdAt
                file {
                  name
                  contentType
                  size
                  url
                }
                probativeAttachment {
                  status
                  fileContentType
                  fileName
                  fileSize
                  fileUrl
                  downloadUrl
                }
              }
            }
          }
        }
      `),
      {
        search: '',
        pagination: {
          page: 1,
          perPage: 10,
        },
        sort: {
          property: 'emitted_at',
          direction: 'desc',
        },
        filterGroup: {
          conditional: 'and',
          expressions: [
            {
              operator: 'eq',
              property: 'id',
              values: [transactionId],
            },
          ],
        },
      }
    );
    return searchTransactions as SearchTransactionsResult;
  };

  const { mutate, isPending } = useMutation({
    mutationFn: fetchUpdatedTransaction,
    onSuccess: async (data, _variables) => {
      const { transactions } = data;
      const [transaction] = transactions;
      const {
        transaction: oldTransaction,
        updateTransactionCache,
        activeQueryKey,
      } = manageActiveTransactionsQueryCache(transactionId, queryClient);

      await queryClient.cancelQueries({ queryKey: activeQueryKey });

      const updatedTransaction = {
        ...oldTransaction,
        ...transaction,
      };
      updateTransactionCache(updatedTransaction);
    },
  });

  return { mutate, isPending };
};
