import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import { useCategoriesManagementRouting } from '../components/cash-flow/components/categories-management/hooks/use-categories-management-routing';
import { useFetchApi } from './use-fetch-api';
import { useCashflowCategories } from './use-cashflow-categories';

interface NewSubcategory {
  name: string;
  vat_rate: string | null;
}

interface ErrorDetails {
  errors: {
    code: string;
    detail: string;
    source: unknown;
  }[];
}
export interface CreateCashFlowSubcategoryRequest {
  categoryId: string;
  payload: NewSubcategory;
}
interface CreateCashFlowSubcategoryResponse extends NewSubcategory {
  id: string;
  order: number;
}

export const useCreateCashFlowSubcategory = (): UseMutationResult<
  CreateCashFlowSubcategoryResponse,
  Error,
  CreateCashFlowSubcategoryRequest
> => {
  const fetchApi = useFetchApi();
  const intl = useIntl();
  const { currentType } = useCategoriesManagementRouting();
  const {
    invalidateCategoriesQuery,
    cancelCategoriesQueries,
    setNewSubcategory,
    setAllCategories,
  } = useCashflowCategories();
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const createCashFlowCategory = async (
    request: CreateCashFlowSubcategoryRequest
  ): Promise<CreateCashFlowSubcategoryResponse> => {
    const { categoryId, payload } = request;

    const response = await fetchApi(
      `${cashFlowCategoriesNamespace}/cash-flow/categories/${categoryId}/subcategories`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      const errorDetails = (await response.json()) as ErrorDetails;
      throw new Error('Subcategory could not be created', { cause: errorDetails.errors[0]?.code });
    }

    return (await response.json()) as CreateCashFlowSubcategoryResponse;
  };

  return useMutation({
    mutationFn: createCashFlowCategory,
    onMutate: async ({ payload, categoryId }) => {
      await cancelCategoriesQueries();

      const { allCategoriesBeforeMutation } = setNewSubcategory(categoryId, currentType, payload);

      return { allCategoriesBeforeMutation };
    },
    onError: (error, __, context) => {
      if (context) {
        setAllCategories(context.allCategoriesBeforeMutation);
      }
      if (error.cause === 'name_already_used') {
        toastFlashMessages.toastError(
          intl.formatMessage({
            id: 'categories-management.create-category.already-exists.toast.error',
          })
        );
      } else {
        toastFlashMessages.toastError(
          intl.formatMessage({ id: 'categories-management.create-subcategory.toast.error' })
        );
      }
    },
    onSuccess: async () => {
      await invalidateCategoriesQuery();
    },
  });
};
