import { useIntl } from 'react-intl';
import { useCallback } from 'react';

export function useDisplayCounterparty(): (value?: string | null) => string {
  const intl = useIntl();

  return useCallback(
    (value?: string | null): string => {
      if (value) {
        return value;
      }
      return intl.formatMessage({ id: 'transactions.counterparty.unknown' });
    },
    [intl]
  );
}
