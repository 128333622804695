import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import type { CashflowCategorySide } from '../models/cash-flow-category';
import { useFetchApi } from './use-fetch-api';
import { useCashflowCategories } from './use-cashflow-categories';

export interface DeleteCashFlowCategoryRequest {
  categoryId: string;
  type: CashflowCategorySide;
}

export const useDeleteCashFlowCategory = (): UseMutationResult<
  void,
  Error,
  DeleteCashFlowCategoryRequest
> => {
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const {
    cancelCategoriesQueries,
    invalidateCategoriesQuery,
    setAllCategories,
    removeCategoryById,
  } = useCashflowCategories();
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const deleteCashFlowCategory = async (request: DeleteCashFlowCategoryRequest): Promise<void> => {
    const { categoryId } = request;
    const response = await fetchApi(
      `${cashFlowCategoriesNamespace}/cash-flow/categories/${categoryId}`,
      {
        method: 'DELETE',
      }
    );

    if (!response.ok) throw Error(`Category ${categoryId} could not be deleted`);
  };

  return useMutation({
    mutationFn: deleteCashFlowCategory,
    onMutate: async ({ categoryId, type }) => {
      await cancelCategoriesQueries();
      const { allCategoriesBeforeMutation } = removeCategoryById(categoryId, type);

      return { categoryId, allCategoriesBeforeMutation };
    },
    onError: (_, __, context) => {
      if (context) {
        setAllCategories(context.allCategoriesBeforeMutation);
      }
      toastFlashMessages.toastError(
        formatMessage({ id: 'categories-management.delete-category.toast.error' })
      );
    },
    onSuccess: async () => {
      await invalidateCategoriesQuery();
      toastFlashMessages.toastSuccess(
        formatMessage({ id: 'categories-management.delete-category.toast.success' })
      );
    },
  });
};

export interface DeleteCashFlowSubcategoryRequest {
  categoryId: string;
  subcategoryId: string;
  type: CashflowCategorySide;
}

export const useDeleteCashFlowSubcategory = (): UseMutationResult<
  void,
  Error,
  DeleteCashFlowSubcategoryRequest
> => {
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const {
    cancelCategoriesQueries,
    invalidateCategoriesQuery,
    removeSubcategoryById,
    setAllCategories,
  } = useCashflowCategories();

  const toastFlashMessages = useEmberService('toast-flash-messages');

  const deleteCashFlowSubcategory = async (
    request: DeleteCashFlowSubcategoryRequest
  ): Promise<void> => {
    const { categoryId, subcategoryId } = request;
    const response = await fetchApi(
      `${cashFlowCategoriesNamespace}/cash-flow/categories/${categoryId}/subcategories/${subcategoryId}`,
      {
        method: 'DELETE',
      }
    );

    if (!response.ok)
      throw Error(`Subcategory ${subcategoryId} under category ${categoryId} could not be deleted`);
  };

  return useMutation({
    mutationFn: deleteCashFlowSubcategory,
    onMutate: async ({ categoryId, subcategoryId, type }) => {
      await cancelCategoriesQueries();
      const { allCategoriesBeforeMutation } = removeSubcategoryById(
        categoryId,
        subcategoryId,
        type
      );
      return { categoryId, subcategoryId, allCategoriesBeforeMutation };
    },
    onError: (_, __, context) => {
      if (context) {
        setAllCategories(context.allCategoriesBeforeMutation);
      }
      toastFlashMessages.toastError(
        formatMessage({ id: 'categories-management.delete-subcategory.toast.error' })
      );
    },
    onSuccess: async () => {
      await invalidateCategoriesQuery();
      toastFlashMessages.toastSuccess(
        formatMessage({ id: 'categories-management.delete-subcategory.toast.success' })
      );
    },
  });
};
