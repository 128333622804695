import { useMutation, useQueryClient, type UseMutateFunction } from '@tanstack/react-query';
import { transactionNamespace } from 'qonto/constants/hosts';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import type { Transaction, Label } from 'qonto/react/graphql';
import { manageActiveTransactionsQueryCache } from '../utils/react-query-helpers';

interface ServerPayload {
  label_ids?: string[];
  vat_country?: string;
  vat_amount?: number;
  note?: string;
}

interface CachePayload {
  labels?: Label[];
}

export interface MutationPayload {
  serverPayload: ServerPayload;
  cachePayload: CachePayload;
}

export const useUpdateTransactions = (
  transactionId: string
): {
  mutate: UseMutateFunction<void, Error, MutationPayload>;
} => {
  const queryClient = useQueryClient();
  const fetchApi = useFetchApi();

  const updateTransaction = async (payload: MutationPayload): Promise<void> => {
    try {
      const response = await fetchApi(`${transactionNamespace}/transactions/${transactionId}`, {
        method: 'PUT',
        body: JSON.stringify({
          transaction: payload.serverPayload,
        }),
      });
      if (!response.ok) throw Error('Transaction could not be saved');
    } catch (e) {
      throw new Error('Transaction could not be saved');
    }
  };

  const { mutate } = useMutation({
    mutationFn: updateTransaction,
    onMutate: async variables => {
      const { transaction, updateTransactionCache, activeQueryKey } =
        manageActiveTransactionsQueryCache(transactionId, queryClient);

      const oldTransaction = transaction;

      await queryClient.cancelQueries({ queryKey: activeQueryKey });
      if (variables.cachePayload.labels) {
        const updatedTransaction = {
          ...transaction,
          labels: variables.cachePayload.labels,
        } as Transaction;
        updateTransactionCache(updatedTransaction);
      }

      return {
        oldTransaction,
        updateTransactionCache,
      };
    },
    onError: (err, variables, context) => {
      if (context) {
        const { oldTransaction, updateTransactionCache } = context;
        updateTransactionCache(oldTransaction);
      }
    },
  });

  return { mutate };
};
