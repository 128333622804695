import {
  type TransactionCategory,
  type TransactionStatus,
  type TransactionSide,
  type StatusHistory,
} from 'qonto/react/constants';
import type { TransactionOperationMethod } from '../constants/transactions';
import type { Model } from './model';
import type { Label } from './label';
import type { Thread } from './thread';
import type { BankAccountModel } from './bank-account';
import type { CardModel } from './card';
import type { IncomeModel } from './income';
import type { TransferModel } from './transfer';
import type { CashflowTransactionCategory } from './cash-flow-category';

export interface TransactionModel extends Model {
  id: string;
  amount: {
    currency: 'EUR';
    value: string;
  };
  category: TransactionCategory;
  emittedAt: string;
  localAmount: {
    currency: 'EUR';
    value: string;
  };
  operationMethod: TransactionOperationMethod;
  operationType: string | null;
  reference: string;
  settledAt: string | null;
  settledBalance: {
    currency: 'EUR';
    value: string;
  };
  categoryAssignment: CashflowTransactionCategory | null;
  initiatorId: string | null;
  initiator: TransactionInitiator;
  status: TransactionStatus;
  counterpartyName: string;
  rawCounterpartyName: string;
  statusHistory: StatusHistory;
  slug: string;
  enrichmentData: {
    logo?: {
      small: string;
      medium: string;
    };
  };
  side: TransactionSide;
  note: string | null;
  labels: Label[];
  attachmentLost: boolean;
  attachmentRequired: boolean;
  qualifiedForAccounting: boolean;
  attachments: Attachment[];
  receivableInvoices: ReceivableInvoice[];
  payableInvoices: PayableInvoice[];
  subject: SubjectType | null;
  bankAccount: BankAccountModel | null;
  thread: Thread | null;
  isInstantTransfer: boolean;

  /**
   * transactions v5 properties
   */
  vatAmount: number | null;
  vatRate: number | null;
  vatCountry: string;
  vatStatus: string;
}

export interface TransactionInitiator {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  role: string;
  avatar: {
    url: string;
  };
  avatarThumb: {
    url: string;
  };
  team: {
    name: string;
  };
}

export type SubjectType = CardModel | IncomeModel | TransferModel;

export interface Attachment {
  id: string;
  type: 'attachment';
  thumbnail: {
    fileUrl: string;
  };
  slug: string;
  attachmentType: string;
  downloadUrl: string;
  createdAt: string;
  file: {
    fileName: string;
    fileContentType: string;
    fileSize: string;
    fileUrl: string;
  };
  probativeAttachment?: ProbativeAttachment;
}

export interface ProbativeAttachment {
  id: string;
  type: 'probative_attachment';
  status?: string;
  fileName?: string;
  fileContentType?: string;
  fileSize?: string;
  fileUrl?: string;
  downloadUrl?: string;
}

export interface ReceivableInvoice {
  id: string;
  type: 'receivable_invoice';
  attachment?: Attachment;
  number: string | null;
}

export interface PayableInvoice {
  id: string;
  type: 'payable_invoice';
  attachment?: Attachment;
  number: string | null;
}

export const ATTACHMENT_STATUS = {
  LOST: 'lost',
  REQUIRED: 'required',
  NOT_REQUIRED: 'not required',
} as const;

export type AttachmentStatusOption = (typeof ATTACHMENT_STATUS)[keyof typeof ATTACHMENT_STATUS];
