import { useQueryClient } from '@tanstack/react-query';
import { manageActiveTransactionsQueryCache } from 'qonto/react/utils/react-query-helpers';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';
import type { Transaction } from 'qonto/react/graphql';
import { ATTACHMENT_STATUS, type AttachmentStatusOption } from '../models/transaction';

export const useBulkAttachmentStatus = (): {
  attachmentStatus: AttachmentStatusOption | null;
  allHaveAttachments?: boolean;
} => {
  const queryClient = useQueryClient();
  const { selection: selectedTransactionIds } = bulkSelectionManager.useBulkSelection();
  if (!selectedTransactionIds[0]) return { attachmentStatus: null };

  const { getTransactionsFromIds } = manageActiveTransactionsQueryCache(
    selectedTransactionIds[0],
    queryClient
  );
  const selectedTransactions = getTransactionsFromIds(selectedTransactionIds);
  if (!selectedTransactions[0]) return { attachmentStatus: null };

  return computeAggregateStatus(selectedTransactions);
};

export const bulkAttachmentManager = {
  useBulkAttachmentStatus,
};

export function computeAggregateStatus(selectedTransactions: Transaction[]): {
  attachmentStatus: AttachmentStatusOption | null;
  allHaveAttachments?: boolean;
} {
  const areAllAttachmentRequired = selectedTransactions.every(
    ({ attachmentRequired, attachmentLost }) => attachmentRequired && !attachmentLost
  );
  const areAllAttachmentLost = selectedTransactions.every(({ attachmentLost }) => attachmentLost);
  const areAllAttachmentNotRequired = selectedTransactions.every(
    ({ attachmentRequired }) => !attachmentRequired
  );
  const allHaveAttachments = selectedTransactions.every(
    ({ attachmentIds }) => attachmentIds.length > 0
  );

  let attachmentStatus: AttachmentStatusOption | null = null;
  if (allHaveAttachments) {
    attachmentStatus = ATTACHMENT_STATUS.REQUIRED;
  } else if (areAllAttachmentLost) {
    attachmentStatus = ATTACHMENT_STATUS.LOST;
  } else if (areAllAttachmentRequired) {
    attachmentStatus = ATTACHMENT_STATUS.REQUIRED;
  } else if (areAllAttachmentNotRequired) {
    attachmentStatus = ATTACHMENT_STATUS.NOT_REQUIRED;
  }

  return {
    attachmentStatus,
    allHaveAttachments,
  };
}
