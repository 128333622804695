import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useMutation, useQueryClient, type UseMutateFunction } from '@tanstack/react-query';
import { bulkTransactionsManager } from '../contexts/bulk-transactions-context';
import { bulkSelectionManager } from '../contexts/bulk-selection-context';

export const useUpdateBulkActions = (): {
  mutate: UseMutateFunction<void>;
  isPending: boolean;
} => {
  const {
    labels: { aggregatedUpdatedLabels, mutationFn: mutateBulkUpdates },
    verificationStatus: { verificationStatusChanged, mutationFn: mutateBulkVerificationStatus },
    category: { categoryChanged },
    attachmentStatus: { attachmentStatusChanged, mutationFn: mutateBulkAttachmentStatus },
    requestAttachment: { requestAttachment: attachmentRequestedChanged },
  } = bulkTransactionsManager.useBulkTransactions();
  const { selection: selectedTransactions } = bulkSelectionManager.useBulkSelection();
  const queryClient = useQueryClient();
  const hasUpdatedLabels = aggregatedUpdatedLabels.length > 0;
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const submitBulkActionsFn = async (): Promise<void> => {
    const changedFieldsMutationFns = [];

    if (hasUpdatedLabels || categoryChanged || attachmentRequestedChanged) {
      changedFieldsMutationFns.push(mutateBulkUpdates);
    }

    if (verificationStatusChanged) {
      changedFieldsMutationFns.push(mutateBulkVerificationStatus);
    }

    if (attachmentStatusChanged) {
      changedFieldsMutationFns.push(mutateBulkAttachmentStatus);
    }

    await Promise.all(changedFieldsMutationFns.map(fn => fn(selectedTransactions)));
  };

  const { mutate, isPending } = useMutation({
    mutationFn: submitBulkActionsFn,
    onSuccess: async () => {
      const numberOfTransactions = selectedTransactions.length;

      await queryClient.invalidateQueries({ queryKey: ['search-transactions-graphql'] });
      toastFlashMessages.toastInfo(
        `Your changes have been applied to ${numberOfTransactions.toString()} transactions`
      );
    },
    onError: () => {
      toastFlashMessages.toastError('Your changes could not be saved');
    },
  });

  return {
    mutate,
    isPending,
  };
};
