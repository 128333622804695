import { useCallback, useMemo, useState } from 'react';
import { Uppy, type UppyFile, type UploadResult, type UppyOptions } from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import useUppyEvent from '@uppy/react/lib/useUppyEvent';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { MAX_ATTACHMENT_COUNT, UPLOAD_DISCLAIMER_TIMEOUT } from '../constants';

export interface Body {
  [key: string]: unknown;
  attachment: {
    id: string;
    organization_id: string;
    file?: {
      file_name: string;
      file_content_type: string;
      file_size: string;
      file_url: string;
    };
    probative_attachment?: {
      status: string;
    };
    thumbnail?: {
      file_url: string;
    };
    download_url: string;
    slug: null;
    created_at: string;
    attachment_type: 'attachment';
  };
}

export interface Meta {
  [key: string]: unknown;
  'attachment[organization_id]': string;
  'attachment[bank_account_id]': string;
}

type File = UppyFile<Meta, Body>;

interface UseAttachmentsUploaderProps extends UppyOptions<Body, Meta> {
  endpoint: string;
  onComplete: (result: UploadResult<Meta, Body>) => void;
  onFileUploadSuccess?: (
    file: File | undefined,
    response: Omit<NonNullable<File['response']>, 'uploadURL'> | undefined
  ) => void;
  onFileUploadError?: (
    file: File | undefined,
    error: { name: string; message: string; details?: string },
    response?: Omit<NonNullable<File['response']>, 'uploadURL'> | undefined
  ) => void;
  minNumberOfFiles?: number;
  maxNumberOfFiles?: number;
  minFileSize?: number;
  maxFileSize?: number;
  allowedFileTypes?: string[];
  buttonText?: string;
  disclaimerInfoString?: Record<string, string>;
}

export function useAttachmentsUploader({
  onFileUploadSuccess,
  onFileUploadError,
  onComplete,
  minNumberOfFiles = 1,
  maxNumberOfFiles = 5,
  endpoint = 'v3/attachments',
  maxFileSize = 15 * 1e6 /* 15MB */,
  allowedFileTypes = ['image/*', 'application/pdf'],
  disclaimerInfoString,
}: Omit<UseAttachmentsUploaderProps, 'restrictions'>): Uppy<Meta, Body> {
  const networkManager = useEmberService('network-manager');
  const { formatMessage } = useIntl();
  const headers = useMemo(() => {
    const requestHeaders = { ...networkManager.requestHeaders } as Record<string, string>;
    delete requestHeaders['Content-Type'];
    return requestHeaders;
  }, [networkManager.requestHeaders]);
  const { organization } = useOrganizationManager();
  const defaultErrorMessage = formatMessage({
    id: 'transactions.table.attachments.popover.error.upload-failed',
  });
  const youCanOnlyUploadX =
    disclaimerInfoString?.youCanOnlyUploadX ??
    formatMessage(
      {
        id: 'transactions.table.attachments.popover.disclaimer.upload-limit',
      },
      { count: MAX_ATTACHMENT_COUNT }
    );

  /* eslint-disable-next-line react/hook-use-state -- /
    Pass an initializer function to prevent Uppy from being recreated on every render
    https://uppy.io/blog/uppy-4.0/#useuppystateuppy-selector
  */
  const [uppy] = useState(() =>
    new Uppy<Meta, Body>({
      restrictions: {
        maxNumberOfFiles,
        minNumberOfFiles,
        maxFileSize,
        allowedFileTypes,
      },
      autoProceed: true,
      infoTimeout:
        UPLOAD_DISCLAIMER_TIMEOUT /* Long timeout - 1 hour because we clear the state ourselves */,
      locale: {
        strings: {
          // See https://uppy.io/docs/uppy/#locale for more information on the locale object
          youCanOnlyUploadX,
          failedToUpload: disclaimerInfoString?.failedToUpload ?? defaultErrorMessage,
          exceedsSize: disclaimerInfoString?.exceedsSize ?? defaultErrorMessage,
          noInternetConnection: disclaimerInfoString?.noInternetConnection ?? defaultErrorMessage,
          youCanOnlyUploadFileTypes:
            disclaimerInfoString?.youCanOnlyUploadFileTypes ?? defaultErrorMessage,
        },
        pluralize: () => 0,
      },
      onBeforeUpload(
        files: Record<string, UppyFile<Meta, Body>>
      ): Record<string, UppyFile<Meta, Body>> | boolean {
        const info = uppy.getState().info;
        const hasExceededMaxNumberOfFiles = info.some(
          ({ type, message }) => type === 'error' && message === youCanOnlyUploadX
        );

        if (hasExceededMaxNumberOfFiles) {
          uppy.cancelAll();
          return false;
        }
        return files;
      },
    }).use(XHRUpload, {
      endpoint,
      fieldName: 'attachment[file]',
      formData: true,
      withCredentials: true,
      headers,
    })
  );

  const handleFileAdded = useCallback(
    (file: File) => {
      uppy.setFileMeta(file.id, {
        'attachment[organization_id]': organization.organizationId,
        'attachment[bank_account_id]': organization.primaryAccountId,
      });
    },
    [organization, uppy]
  );

  const handleFileUploadSuccess = useCallback(
    (file: File | undefined, response: NonNullable<File['response']>) => {
      if (!file) {
        return;
      }
      onFileUploadSuccess?.(file, response);
    },
    [onFileUploadSuccess]
  );

  const handleFileUploadError = useCallback(
    (
      file: File | undefined,
      error: { name: string; message: string; details?: string },
      response?: Omit<NonNullable<File['response']>, 'uploadURL'> | undefined
    ) => {
      if (!file) {
        return;
      }
      onFileUploadError?.(file, error, response);
    },
    [onFileUploadError]
  );

  useUppyEvent(uppy, 'file-added', handleFileAdded);
  useUppyEvent(uppy, 'upload-success', handleFileUploadSuccess);
  useUppyEvent(uppy, 'upload-error', handleFileUploadError);
  useUppyEvent(uppy, 'complete', onComplete);

  return uppy;
}
